<template>
    <v-container fluid>
        <v-card flat>
            <v-card-title class="text-h4">Configure Kiosk <v-chip color="primary"
                    class="ml-5 font-weight-bold text--h5 white--text">{{ config.name }} - {{ config.kioskType
                    }}</v-chip>
                <v-spacer></v-spacer>
                <v-btn width="125" color="primary" @click="$router.push('/kioskManagement')">Kiosk List</v-btn>
            </v-card-title>
        </v-card>
        <v-card flat :height="configureKioskDimension.outerCardHeight" class="mt-3">
            <v-row no-gutters class="fill-height">
                <v-col :cols="colLength" class="px-10" align-self="center" align="center">
                    <span class="text-h6">Application Configuration</span>
                    <v-card flat :height="configureKioskDimension.innerCardHeight" class="overflow-y-auto" v-if="config.kioskType != 'Form Filing'">
                        <v-form ref="cmsConfigKioskForm" lazy-validation class="pa-6">
                            <v-text-field v-model="config.cmsLink" label="CMS Address URL (https://...)"
                                :rules="rules.cmsAddressRules" required outlined dense>
                            </v-text-field>
                            <v-text-field label="ARS Browser Kiosk Id" required outlined dense
                                v-model="config.applicationId"></v-text-field>
                            <v-select :items="getClientConfig.courtInfo" v-model="config.locationCode" dense outlined
                                hide-details label="Kiosk Location Code" item-text="alias"></v-select>
                            <v-select :items="getClientConfig.courtInfo" class="mt-5" dense outlined
                                label="Kiosk Department" v-model="config.kioskDepartment"
                                item-text="courtType"></v-select>
                            <v-text-field
                                label="Default Office Location (Add map location here for user to send Ex. Clerk's Office)"
                                v-model="config.defaultLocation" outlined dense></v-text-field>
                            <v-select v-model="config.languages" multiple :items="getClientConfig.languages"
                                item-text="name" item-value="code" outlined dense
                                label="Select Kiosk Languages"></v-select>
                            <v-select v-model="config.modules" multiple :items="getClientConfig.modules"
                                item-text="name" item-value="alias" outlined dense
                                label="Select Kiosk Modules"></v-select>
                            <v-switch v-model="config.physicalKeyboard" label="Physical Keyboard" color="green" dense
                                hide-details class="ml-4"></v-switch>
                            <v-card-title class="justify-center">Case Management</v-card-title>
                            <v-text-field label="Enter API URL" outlined
                                v-model="config.hearingApi" dense></v-text-field>
                            <v-card-title class="justify-center">Telepresence Configuration</v-card-title>
                            <v-switch v-model="config.telepresence" label="Telepresence" color="green" hide-details
                                dense class="ml-5"></v-switch>
                            <v-form v-if="config.telepresence" class="mt-2">
                                <v-text-field v-model="config.videoLink" label="Telepresence URL (https://...)"
                                    :rules="rules.videoLinkRules" required outlined dense>
                                </v-text-field>
                            </v-form>
                        </v-form>
                    </v-card>
                    <v-card v-else flat :height="configureKioskDimension.innerCardHeight" class="overflow-y-auto">
                        <v-form ref="cmsConfigKioskForm" lazy-validation class="pa-6">
                            <v-text-field v-model="config.cmsLink" label="CMS Address URL (https://...)"
                                :rules="rules.cmsAddressRules" required outlined dense>
                            </v-text-field>
                            <v-text-field label="ARS Browser Kiosk Id" required outlined dense
                                v-model="config.applicationId"></v-text-field>
                                <v-select v-model="config.modules" multiple :items="getClientConfig.efileModules"
                                item-text="name" item-value="alias" outlined dense
                                label="Select Kiosk Modules"></v-select>
                                <v-card-title class="justify-center">Telepresence Configuration</v-card-title>
                            <v-switch v-model="config.telepresence" label="Telepresence" color="green" hide-details
                                dense class="ml-5"></v-switch>
                            <v-form v-if="config.telepresence" class="mt-2">
                                <v-text-field v-model="config.videoLink" label="Telepresence URL (https://...)"
                                    :rules="rules.videoLinkRules" required outlined dense>
                                </v-text-field>
                            </v-form>
                        </v-form>
                    </v-card>
                </v-col>
                <v-col :cols="colLength" class="px-10" align-self="center" align="center">
                    <span class="text-h6">Avatar Configuration</span>
                    <v-card flat :height="configureKioskDimension.innerCardHeight">
                        <v-row no-gutters align="center" justify="center" class="my-3">
                            <v-col cols="12" align="center" class="my-4">
                                <v-btn color="primary" width="150" @click="selectAvatar()">
                                    <v-icon>mdi-plus</v-icon>
                                    Add Avatar
                                </v-btn>
                            </v-col>
                            <v-list v-if="selectedAvatar.length > 0" width="70%"
                                :height="configureKioskDimension.innerCardHeight - 75" class="overflow-y-auto">
                                <v-alert dense type="info" class="white--text alertBorder" width="100%"
                                    v-if="selectedAvatar.length > 1">
                                    If more than 1 avatar is selected. Avatar will be changed after every user session.
                                </v-alert>
                                <v-list-item v-for="(avatar, i) in selectedAvatar" :key="i">
                                    <v-list-item-avatar>
                                        <v-img :src="getCurrentAvatarImage(avatar)"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ avatar.avatarName }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon @click="deleteAvatar(avatar.avatarId)">
                                        <v-icon color="red">
                                            mdi-delete
                                        </v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                            <!-- <v-col cols="4" v-if="selectedAvatar !== null && selectedAvatar !== undefined">
                                <v-img :src="getCurrentAvatarImage()" contain></v-img>
                            </v-col>
                            <v-col cols="3" align-self="center"
                                v-if="selectedAvatar !== null && selectedAvatar !== undefined">
                                <span class="text-subtitle-1 font-weight-bold">{{ selectedAvatar.avatarName }}</span>
                            </v-col> -->
                        </v-row>
                    </v-card>

                </v-col>
                <v-col :cols="colLength" class="px-10" align-self="center" align="center">
                    <span class="text-h6">QNA Configuration</span>
                    <v-card flat :height="configureKioskDimension.innerCardHeight" class="overflow-y-auto">
                        <v-switch v-model="config.englishQna.enable" label="Enable English" class="px-6"></v-switch>
                        <v-form ref="avatarConfigKioskForm" lazy-validation class="px-6">
                            <v-text-field v-model="config.englishQna.kbName" label="Enter Azure KB name"
                                :rules="rules.generalFieldRule" required outlined dense>
                            </v-text-field>
                        </v-form>
                        <v-switch v-model="config.spanishQna.enable" label="Enable Spanish" class="px-6"></v-switch>
                        <v-form ref="avatarConfigKioskForm" lazy-validation class="px-6"
                            v-if="config.spanishQna.enable">
                            <v-text-field v-model="config.spanishQna.kbName" label="Enter Azure KB name"
                                :rules="rules.generalFieldRule" required outlined dense>
                            </v-text-field>
                        </v-form>
                    </v-card>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn color="primary" width="200" @keyup.enter="saveKioskConfig" @click="saveKioskConfig">Save
                        Configuration</v-btn>
                </v-col>
            </v-row>
        </v-card>

    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'configureKiosk',
    data() {
        return {
            colLength: 4,
            hearingType: "",
            config: {
                englishQna: {
                    enable: true,
                    kbName: ""
                },
                spanishQna: {
                    enable: false,
                    kbName: ""
                },
                kId: null,
                name: "",
                avatarInfo: [],
                locationCode: "",
                defaultLocation: "",
                kioskDepartment: "",
                courts: [],
                languages: [],
                modules: [],
                telepresence: false,
                videoLink: "",
                physicalKeyboard: false,
                configured: false,
                hearingApi: "",
                kioskType: "",
                arsKioskId: ""
            },
            rules: {
                cmsAddressRules: [(v) => !!v || "CMS address is required"],
                kioskLocationRules: [(v) => !!v || "Kiosk Location is required"],
                routeRules: [(v) => !!v || "Route is required"],
                endpointRules: [(v) => !!v || "Endpoint key is required"],
                videoLinkRule: [(v) => !!v || "Telepresence URL is required"],
                generalFieldRule: [(v) => !!v || "Field cannot be left empty"],
            }
        }
    },
    computed: {
        ...mapGetters(['getClientConfig', 'selectedKiosk', 'avatarList', 'configureKioskDimension', 'selectedAvatar'])
    },
    methods: {
        saveKioskConfig() {
            if (
                this.$refs.cmsConfigKioskForm.validate() &&
                // this.$refs.chatbotConfigKioskForm.validate() &&
                this.$refs.avatarConfigKioskForm

            ) {
                this.loading = true;
                console.log('New Config: ', this.config)
                this.$store.dispatch("saveKioskConfig", this.config).then(
                    (response) => {
                        this.loading = false;
                        this.show = !this.show;
                        this.$store.dispatch("showSnackBar", {
                            text: response,
                            color: "success",
                        });
                    },
                    (error) => {
                        this.$store.dispatch("showSnackBar", {
                            text: error,
                            color: "error",
                        });
                    }
                );
            }
        },
        getCurrentAvatarImage(avatar) {
            if (avatar !== undefined) {
                this.avatarName = avatar.avatarName
                let temp = this.avatarList.filter(ava => ava.avatarId === avatar.avatarId)
                return 'https://strapi.arsconnect.com' + temp[0].avatarImage.url
            }
        },
        selectAvatar() {
            this.$store.commit('setSelectAvatarViewer', true)
        },
        updateAvatar(avatar) {
            console.log('Selected AVatar  at Edit : ', avatar)
            this.$store.commit('openUpdateAvatarViewer', {
                show: true,
                avatarObj: avatar
            })
        },
        deleteAvatar(id) {
            let index = this.selectedAvatar.findIndex(ava => ava.avatarId === id)
            this.$store.commit("removeAvatar", index)
        }
    },
    mounted() {
        this.$store.commit('clearAvatarSelection')
        console.log("Selected Kiosk: ", this.selectedKiosk.data())
        this.config = this.selectedKiosk.data();
        this.config.kId = this.selectedKiosk.id;

        if (this.selectedKiosk.data().avatarInfo.length > 0 && this.selectedAvatar.length === 0) {
            this.selectedKiosk.data().avatarInfo.forEach(avatar => {
                this.$store.commit('setSelectedAvatar', avatar)
            });
        }
    }
}
</script>